import SmarteditController from '@/utils/Controllers/SmarteditController';

const updateVideoSourceAndLoad = videos => {
  Array.from(videos).forEach(video => {
    const source = video.getElementsByTagName('source')[0];

    if (window.innerWidth < 768) {
      source.setAttribute('src', video.dataset.mobileSrc);
    } else {
      source.setAttribute('src', video.dataset.desktopSrc);
    }
    video.load();
  });
};

export const initVideoResponsive = videos => {
  if (videos && videos.length > 0) {
    let lastWidth = window.innerWidth;
    updateVideoSourceAndLoad(videos);
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768 && lastWidth >= 768) {
        updateVideoSourceAndLoad(videos);
      } else if (window.innerWidth >= 768 && lastWidth < 768) {
        updateVideoSourceAndLoad(videos);
      }
      lastWidth = window.innerWidth;
    });
  }
};

const initVideoComponent = () => {
  const entries = SmarteditController.getEditorialEntries('.js-dlg-video');
  initVideoResponsive(entries);
};

export default () => {
  SmarteditController.addOnReprocessPageListener(initVideoComponent);
  initVideoComponent();
};
