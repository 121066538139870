/* eslint-disable import/prefer-default-export */
import getReviewImages from '@/api/get/reviewImages';
import WriteReviewPopupLoader from '@/components/WriteReviewPopupLoader';
import isAuth from '@/api/get/isAuth';
import GigyaLoginPopupController from '@/utils/Controllers/Gigya/GigyaLoginPopupController';

const writeReviewPopup = async evt => {
  const cta = evt.target;
  const review = { ...cta.dataset };
  const { productCode } = review;
  review.productImages = await getReviewImages({ productCode });
  WriteReviewPopupLoader.load({
    evt,
    review,
    setAlreadyReviewed: () => {
      cta.disabled = true;
    },
  });
};

const handleWriteReview = async e => {
  const cta = e.target;
  const isLogged = await isAuth();
  if (!isLogged) {
    GigyaLoginPopupController.init({
      successCB: () => writeReviewPopup(e),
      header: cta.dataset.loginHeader,
      subHeader: cta.dataset.loginSubheader,
    });
  } else {
    writeReviewPopup(e);
  }
};

export const initWriteReviewPopup = selector => {
  const writeReviewCta = document.querySelector(selector);
  if (writeReviewCta) {
    writeReviewCta.addEventListener('click', handleWriteReview);
  }
};
