import CONST from '@/utils/Constants/General';

export default {
  ACCOUNT: {
    ORDERS: `${DLG.config.encodedContextPath}/my-account/orders`,
    REGISTERED_PRODUCT: `${DLG.config.encodedContextPath}/my-account/registered-products`,
    SAVED_CARTS: `${DLG.config.encodedContextPath}/my-account/saved-carts`,
    SAVED_RECIPES: `${DLG.config.encodedContextPath}/my-account/saved-recipes`,
    WISHLIST: `${DLG.config.encodedContextPath}/my-account/wishlist`,
  },
  CHECKOUT: {
    STEP_ONE: `${DLG.config.encodedContextPath}/checkout/step1`,
    STEP_TWO: '/checkout/step2',
    STEP_THREE: '/checkout/step3',
    STEP_FOUR: '/checkout/step4',
    THANK_YOU: `${DLG.config.encodedContextPath}/checkout/orderConfirmation`,
  },
  PATH: {
    SUPPORT_PAGE: `${DLG.config.encodedContextPath}/support`,
  },
  PRODUCT_REGISTRATION: {
    LANDING: `${DLG.config.encodedContextPath}/product-registration`,
    SEARCH: `${DLG.config.encodedContextPath}/product-registration/search`,
    SELECT: `${DLG.config.encodedContextPath}/product-registration/select`,
    USER_DETAIL: `${DLG.config.encodedContextPath}/product-registration/user-detail`,
    USER_DETAIL_NO_CONTEXT_PATH: `/product-registration/user-detail`,
    DETAIL: `${DLG.config.encodedContextPath}/product-registration/detail`,
    DETAIL_NO_CONTEXT_PATH: `/product-registration/detail`,
    CONFIRMATION: `${DLG.config.encodedContextPath}/product-registration/confirmation`,
  },
  INSTRUCTION_MANUALS: `${DLG.config.encodedContextPath}/manuals`,
  FAQS: `${DLG.config.encodedContextPath}/faqs`,
  LOGIN: `${DLG.config.encodedContextPath}/login`,
  REGISTER: `${DLG.config.encodedContextPath}/register`,
  CONTACT_US: `${DLG.config.encodedContextPath}/contact-us`,
  PRODUCT: {
    WHERE_TO_BUY: `${DLG.config.encodedContextPath}/where-to-buy`,
  },
  CART: `${DLG.config.encodedContextPath}/cart`,
  RECIPES: `${DLG.config.encodedContextPath}/recipes`,
  RECIPE: `${DLG.config.encodedContextPath}/recipe`,
  WISHLIST: `${DLG.config.encodedContextPath}/wishlist`,
  LANGUAGE_SELECTION: `/en/language-selection`,
  SEARCH: `${DLG.config.encodedContextPath}/search`,
  SEARCH_PRODUCTS: text =>
    `${DLG.config.encodedContextPath}/search?text=${text}&searchType=${CONST.SEARCH.TYPE.PRODUCT}`,
  SEARCH_EDITORIAL: text =>
    `${DLG.config.encodedContextPath}/search?text=${text}&searchType=${CONST.SEARCH.TYPE.EDITORIAL}`,
  SUPPORT: `${DLG.config.encodedContextPath}/support`,
  ORDER: {
    HISTORY: `${DLG.config.encodedContextPath}/my-account/orders`,
    GUEST: `${DLG.config.encodedContextPath}/order`,
  },
  SUBSCRIPTION: {
    HISTORY: `${DLG.config.encodedContextPath}/my-account/subscriptions`,
    DETAIL: `${DLG.config.encodedContextPath}/my-account/subscription-details`,
    // GUEST: `${DLG.config.encodedContextPath}/subscriptions`,
  },
  PDP_CONFIGURABLE: (product, confID) =>
    `${DLG.config.encodedContextPath}/p/${product}?tkcsid=${confID}`,
};
